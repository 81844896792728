import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Inject, Injectable } from '@angular/core';

import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, concatMap, tap, catchError } from 'rxjs/operators';

import { ICampaignWebhook, IConsentCampaign } from '@consent/consent-campaign.model';
import { IConsentCampaignShareRule } from '@consent/consent-campaign-embed-type/consent-campaign-share-rules/consent-campaign-share-rule.model';
import { IConsentCampaignPreferencesData } from './consent-campaign-preferences-data.model';
import { IConsentCampaignRequest } from '@consent/consent-campaign-request/consent-campaign-request.model';
import { mapConsentCampaign, mapConsentUICampaign } from './consent-policy-helpers';
import { ISurveyVersion } from './consent-survey-version.model';
import { AlertService } from '@core/alert';
import { IDataSource, IGridRow, IPaginationResponse, NotificationType, IReviewer } from '@core/models';
import { DatasetsService } from '@datasets/datasets.service';
import { UserService } from '@shared/services/user.service';
import { IStatusChange } from '@shared/services/status-change.model';
import { RecipientsService } from '@shared/services/recipients.service';
import { StatusChangeService } from '@shared/services/status-change.service';
import { ICustomFormOption } from '@shared/features/custom-forms/custom-form-option.model';
import { ConfirmDialogComponent } from '@shared/components/modals/confirm-dialog/confirm-dialog.component';
import { getAvoidLoaderHeaders } from '@shared/helpers/helpers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConsentPolicyService {
  cachedDdatasources$: Observable<IDataSource[]>;
  cachedReviewers$: Observable<IReviewer[]>;
  unmappedBackendCampaigns$ = new BehaviorSubject<any>(null);
  modalRef: NgbModalRef;

  constructor(
    @Inject(DOCUMENT) protected document,
    private alertService: AlertService,
    private datasetService: DatasetsService,
    private domSanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private recipientsService: RecipientsService,
    private statusChangeService: StatusChangeService,
    private translateService: TranslateService,
    protected userService: UserService
  ) {}

  get statusChanged$(): (allowedTypes: NotificationType[]) => Observable<IStatusChange> {
    return this.statusChangeService.statusChanged;
  }

  askForDeleteConfirmation = (consentCampaign: IConsentCampaign): Observable<any> => {
    const { id, title } = consentCampaign;
    const deleteConsentCampaign$ = this.ConsentCampaignDelete(id).pipe(
      tap(() => {
        this.modalRef.close();
        this.modalRef = null;
      })
    );
    const description = this.translateService.instant('modals.delete consent campaign', { consentCampaignTitle: title });
    this.modalRef = this.modalService.open(ConfirmDialogComponent);
    const labels = {
      title: this.translateService.instant('You are about to delete consent campaign'),
      description: description,
      confirm: this.translateService.instant('Delete'),
    };
    this.modalRef.componentInstance.labels = labels;
    return this.modalRef.componentInstance.mainClick.pipe(
      concatMap(() => {
        return deleteConsentCampaign$;
      })
    );
  };

  askForDeactivateConfirmation = (consentCampaign: IConsentCampaign): Observable<any> => {
    const { id, title, reviewer } = consentCampaign;
    const deactivateConsentCampaign$ = this.ConsentCampaignDeactivate(id).pipe(
      tap(() => {
        this.modalRef.close();
        this.modalRef = null;
      })
    );
    let consentLabels = {
      title: this.translateService.instant('Deactivate Campaign'),
      description: this.translateService.instant('Are you sure you want to deactivate the campaign?'),
      confirm: this.translateService.instant('Confirm'),
      cancel: this.translateService.instant('Cancel'),
    };
    if (reviewer) {
      consentLabels = {
        ...consentLabels,
        title: this.translateService.instant('You are about to deactivate consent campaign'),
        description: this.translateService.instant('modals.deactivate consent campaign', { consentCampaignTitle: title }),
        confirm: this.translateService.instant('Deactivate'),
      };
    }
    this.modalRef = this.modalService.open(ConfirmDialogComponent);
    const labels = {
      ...consentLabels,
    };
    this.modalRef.componentInstance.labels = labels;
    return this.modalRef.componentInstance.mainClick.pipe(
      concatMap(() => {
        return deactivateConsentCampaign$;
      })
    );
  };

  deleteCampaignWebhook = (webhookId: string, campaignId: string): Observable<string> => {
    const url = environment.WEBHOOKCAMPAIGNDELETE.replace('{webhookId}', webhookId).replace('{campaignId}', campaignId);
    return this.httpClient.delete<string>(url);
  };

  getConsentCampaignUI = (campaignId: string): Observable<any> => {
    const url = environment.CONSENTCAMPAIGN.replace('{campaignId}', campaignId);
    return this.httpClient.get(url).pipe(
      tap(backendCampaign => {
        this.unmappedBackendCampaigns$.next(backendCampaign);
      }),
      map(mapConsentUICampaign)
    );
  };

  getConsentCampaignConditions = (campaignId: string): Observable<IConsentCampaignShareRule[][]> => {
    const requestOptions = getAvoidLoaderHeaders();
    const url = environment.CONSENTCAMPAIGNCONDITIONS.replace('{campaignId}', campaignId);
    return this.httpClient.get<{ rules: IConsentCampaignShareRule[][] }>(url, requestOptions).pipe(
      map(response => {
        return response.rules;
      }),
      catchError(() => {
        return of([[]]);
      })
    );
  };

  getConsentCampaignFormdata = (consentCampaign: IConsentCampaign): FormData => {
    const mappedCampaign = mapConsentCampaign(consentCampaign);
    const formData = new FormData();
    formData.append('request', new Blob([JSON.stringify(mappedCampaign)], { type: 'application/json' }));
    if (consentCampaign.image) {
      formData.append('image', consentCampaign.image, consentCampaign.image.name);
    }
    if (consentCampaign.backgroundCoverImage && typeof consentCampaign.backgroundCoverImage !== 'string') {
      formData.append('backgroundCoverImage', consentCampaign.backgroundCoverImage, consentCampaign.backgroundCoverImage.name);
    }
    if (consentCampaign.backgroundImage && typeof consentCampaign.backgroundImage !== 'string') {
      formData.append('backgroundImage', consentCampaign.backgroundImage, consentCampaign.backgroundImage.name);
    }
    return formData;
  };

  getConsentCampaignPolicy = (campaignId: string, datasetId: string): Observable<string> => {
    const url = environment.CONSENTCAMPAIGNPOLICY.replace('{campaignId}', campaignId).replace('{datasetId}', datasetId);
    return this.httpClient.get(url, { responseType: 'text' });
  };

  getConsentCampaignSurveyResponseCSV = (campaignId: string, version: string): Observable<any> => {
    const url = environment.CONSENTCAMPAIGNSURVEYCSV.replace('{campaignId}', campaignId).replace('{version}', version);
    return this.httpClient.get(url, { responseType: 'arraybuffer' });
  };

  getConsentCampaignSurveyResponse = (campaignId: string, version: string): Observable<IConsentCampaignPreferencesData[]> => {
    const url = environment.CONSENTCAMPAIGNSURVEYRESPONSES.replace('{campaignId}', campaignId).replace('{version}', version);
    return this.httpClient.get<IConsentCampaignPreferencesData[]>(url);
  };

  getPreferencesResponses = (campaignId: string, version: string): Observable<{ [key: string]: string }[]> => {
    return this.getConsentCampaignSurveyResponse(campaignId, version).pipe(
      map(data => {
        return data.map(SurveyResponse => JSON.parse(SurveyResponse.response));
      })
    );
  };

  getConsentCampaignSurveyVersions = (campaignId: string): Observable<ISurveyVersion[]> => {
    const url = environment.CONSENTCAMPAIGNSURVEYS.replace('{campaignId}', campaignId);
    return this.httpClient.get<ISurveyVersion[]>(url);
  };

  getDataSource = (id: string): Observable<IDataSource> => {
    return this.datasetService.getDataset(id);
  };

  getDatasources = (): Observable<IDataSource[]> => {
    if (!this.cachedDdatasources$) {
      this.cachedDdatasources$ = this.datasetService.getDatasets({ allItems: true }).pipe(map(this.mapDatasourcesList), shareReplay(1));
    }
    return this.cachedDdatasources$;
  };

  getDatasourcesInvited = (): Observable<ICustomFormOption<string>[]> => {
    return this.datasetService.getDatasets({ owner: true, allItems: true }).pipe(map(this.mapDatasourcesList), map(this.mapOptions));
  };

  getCachedDatasources = (invalidateCache = false): Observable<IDataSource[]> => {
    if (invalidateCache) {
      this.invalidateDatasourcesCache();
    }
    return this.getDatasources();
  };

  getCampaignLink = (consentCampaign: IConsentCampaign): string => {
    if (consentCampaign.originalCampaignId) {
      return `${environment.AUTHCONF.returnTo}landings/consent-survey/${consentCampaign.id}?email={{ email }}`;
    }
    return `${environment.AUTHCONF.returnTo}landings/consent-survey/${consentCampaign.id}`;
  };

  getHeaders = (fields: IGridRow[]): ICustomFormOption<string>[] => {
    return this.recipientsService.getHeaders(fields);
  };

  getRecipients = (): Observable<ICustomFormOption<string>[]> => {
    return this.recipientsService.getRecipientOptions();
  };

  getReviewers = (): Observable<IReviewer[]> => {
    if (!this.cachedReviewers$) {
      this.cachedReviewers$ = this.userService.getReviewers().pipe(
        catchError(() => of([])),
        shareReplay(1)
      );
    }
    return this.cachedReviewers$;
  };

  getReviewerOptions = (): Observable<ICustomFormOption<string>[]> => {
    return this.getReviewers().pipe(
      map(reviewers => {
        return reviewers.map(reviewer => {
          return { label: reviewer.userName || reviewer.email, value: reviewer.email };
        });
      })
    );
  };

  getSafeFileURL = (file: File): SafeUrl => {
    const url = this.document.defaultView.URL.createObjectURL(file);
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  };

  getSafeImageURL = (url: string): SafeUrl => {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  };

  getStatusReport = (campaignId: string): Observable<any> => {
    const url = environment.CampaignStatusREPORT.replace('{campaignId}', campaignId);
    return this.httpClient.get(url, { responseType: 'arraybuffer' });
  };

  getSurveyReport = (campaignId: string): Observable<any> => {
    const url = environment.CONSENTCAMPAIGNSURVEYREPORT.replace('{campaignId}', campaignId);
    return this.httpClient.get(url, { responseType: 'arraybuffer' });
  };

  handleConsentCampaignRequest = (
    consentCampaignRequest: IConsentCampaignRequest,
    campaignId: string,
    organizationId
  ): Observable<boolean> => {
    const url = environment.CONSENTCAMPAIGNREQUEST.replace('{campaignId}', campaignId).replace('{publisherOrganizationId}', organizationId);
    return this.httpClient.put<boolean>(url, consentCampaignRequest);
  };

  invalidateDatasourcesCache = (): void => {
    this.cachedDdatasources$ = null;
  };

  invalidateReviewersCache = (): void => {
    this.cachedReviewers$ = null;
  };

  consentCampaignEdit = (consentCampaign: IConsentCampaign): Observable<any> => {
    const formData = this.getConsentCampaignFormdata(consentCampaign);
    const url = environment.CONSENTCAMPAIGNEDIT.replace('{campaignId}', consentCampaign.id);
    return this.httpClient.put(url, formData);
  };

  consentCampaignPublish = (consentCampaignId: string): Observable<any> => {
    const url = environment.CONSENTCAMPAIGNPUBLISH.replace('{campaignId}', consentCampaignId);
    return this.httpClient.post(url, {});
  };

  consentCampaignSave = (consentCampaign: IConsentCampaign): Observable<any> => {
    const formData = this.getConsentCampaignFormdata(consentCampaign);
    return this.httpClient.post(environment.CONSENTCAMPAIGNCREATE, formData);
  };

  consentCampaignEditUnmapped = (consentCampaign: IConsentCampaign): Observable<any> => {
    const formData = new FormData();
    formData.append('request', new Blob([JSON.stringify(consentCampaign)], { type: 'application/json' }));
    const url = environment.CONSENTCAMPAIGNEDIT.replace('{campaignId}', consentCampaign.id);
    return this.httpClient.put(url, formData);
  };

  consentCampaignApprove = (campaignId: string): Observable<any> => {
    const url = environment.CONSENTCAMPAIGNAPPROVE.replace('{campaignId}', campaignId);
    return this.httpClient.post(url, {});
  };

  consentCampaignReject = (campaignId: string): Observable<any> => {
    const url = environment.CONSENTCAMPAIGNREJECT.replace('{campaignId}', campaignId);
    return this.httpClient.post(url, {});
  };

  ConsentCampaignDelete = (id: string): Observable<string> => {
    const url = environment.CONSENTCAMPAIGNDELETE.replace('{campaignId}', id);
    return this.httpClient.delete(url, { responseType: 'text' });
  };

  ConsentCampaignDeactivate = (id: string): Observable<string> => {
    const url = environment.CONSENTCAMPAIGNDEACTIVATE.replace('{campaignId}', id);
    return this.httpClient.post(url, {}, { responseType: 'text' });
  };

  mapDatasourcesList = (datasources: IPaginationResponse<IDataSource>): IDataSource[] => {
    return datasources.list;
  };

  mapOptions = (datasources: IDataSource[]): ICustomFormOption<string>[] => {
    return datasources.map(datasource => ({ label: datasource.name, value: datasource.id }));
  };

  saveCampaignWebhooks = (webhooks: ICampaignWebhook[]): Observable<ICampaignWebhook[]> => {
    return this.httpClient.post<ICampaignWebhook[]>(environment.WEBHOOKCAMPAIGNPOST, webhooks);
  };

  saveCampaignConditions = (campaignId: string, rules: IConsentCampaignShareRule[][]): Observable<IConsentCampaignShareRule[][]> => {
    return this.httpClient
      .post<{ rules: IConsentCampaignShareRule[][] }>(environment.CONSENTCAMPAIGNCONDITIONSSAVE, { campaignId, rules })
      .pipe(
        map(response => {
          return response.rules;
        }),
        catchError(() => {
          return of(rules);
        })
      );
  };

  showDeactivatedMsg = (): void => {
    this.alertService.error('We are sorry that this campaign is not available anymore.');
  };
}
